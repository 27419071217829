import React, { useContext } from 'react'
import { func, string } from 'prop-types'
import { FaRegSun as SunIcon } from 'react-icons/fa'
import { FaRegMoon as MoonIcon } from 'react-icons/fa'
import StyledTooltip from './Tooltip'
import { LanguageContext } from '../utils/LanguageContext'
import { ToggleContainer } from './toogle.styled'

const Toggle = ({ theme, toggleTheme }) => {
  const isLight = theme === 'light'
  const { language } = useContext(LanguageContext)
  const text = !isLight ? `${language.lightMode}` : `${language.darkMode}`
  return (
    <StyledTooltip title={`${language.activate} ${text}`}>
      <ToggleContainer onClick={toggleTheme}>
        {!isLight ? <SunIcon /> : <MoonIcon />}
      </ToggleContainer>
    </StyledTooltip>
  )
}

Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
}

export default Toggle
