export const icons = [
  {
    name: 'youtube',
    label: 'YouTube',
    link: 'https://www.youtube.com/abelroland',
    color: '#FF0000',
  },
  {
    name: 'facebook',
    label: 'Facebook',
    link: 'https://www.facebook.com/abelrolandartist',
    color: '#4267B2',
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/in/abelroland/',
    color: '#0077b5',
  },
  {
    name: 'github',
    label: 'GitHub',
    link: 'https://github.com/abelroland',
    color: '#211f1f',
  },
  {
    name: 'vimeo',
    label: 'Vimeo',
    link: 'https://vimeo.com/abelroland',
    color: '#86C9EF',
  },
  {
    name: 'imdb',
    label: 'IMDb',
    link: 'https://www.imdb.com/name/nm4537873/?ref_=fn_al_nm_1',
    color: '#F3CE13',
  },
]
