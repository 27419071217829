export const EN = {
  value: 'en-US',
  activate: 'Activate',
  darkMode: 'Dark Mode',
  lightMode: 'Light Mode',
  pitch:
    "Full-stack 'imagineer', passionate about art (particularly music & video) and entrepreneurship.",
  phraseOne:
    'This website is an entry-point dedicated to forward you to the most updated info about myself.',
  phraseTwo:
    'You will find more specific info by clicking on the respective social media icon.',
  label: "Abel Roland's",
  copy: 'All rights reserved.',
  contact: 'Contact',
}

export const ES = {
  value: 'es',
  activate: 'Activar',
  darkMode: 'Modo Oscuro',
  lightMode: 'Modo Claro',
  pitch:
    "Full-stack 'imagineer', apasionado por arte (en especial música & video) y emprendimientos.",
  phraseOne:
    'Ese sitio web es un punto de entrada destinado a encaminar las personas hasta las informaciones mas actualizadas a mi respecto.',
  phraseTwo:
    'Para contenidos mas específicos, pulse en los respectivos íconos.',
  label: 'Mi',
  copy: 'Todos los derechos reservados.',
  contact: 'Contacto',
}

export const PT = {
  value: 'pt-BR',
  activate: 'Ativar',
  darkMode: 'Modo noturno',
  lightMode: 'Modo Diurno',
  pitch:
    "'Full-stack imagineiro', apaixonado por arte (particularmente música & vídeo) e empreendedorismo.",
  phraseOne:
    'Esse site é um ponto de entrada dedicado a reencaminhar as pessoas para as informações mais atualizadas a meu respeito.',
  phraseTwo:
    'Para acessar conteúdos mais específicos, clique nos respectivos ícones abaixo.',
  label: 'Meu',
  copy: 'Todos os direitos reservados.',
  contact: 'Contato',
}
