import React, { memo } from 'react'
import styled from 'styled-components'

import { FaYoutube as youtubeIcon } from 'react-icons/fa'
import { FaFacebookSquare as facebookIcon } from 'react-icons/fa'
import { FaLinkedin as linkedinIcon } from 'react-icons/fa'
import { FaGithubSquare as githubIcon } from 'react-icons/fa'
import { FaVimeo as vimeoIcon } from 'react-icons/fa'
import { FaImdb as imdbIcon } from 'react-icons/fa'

const YouTubeIcon = styled(youtubeIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const FacebookIcon = styled(facebookIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const LinkedinIcon = styled(linkedinIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const GithubIcon = styled(githubIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const VimeoIcon = styled(vimeoIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const ImdbIcon = styled(imdbIcon)`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
`

const SvgIconHandler = ({ svgName, color, size = 30 }) => {
  if (svgName === 'youtube') return <YouTubeIcon color={color} size={size} />
  if (svgName === 'facebook') return <FacebookIcon fill={color} size={size} />
  if (svgName === 'linkedin') return <LinkedinIcon fill={color} size={size} />
  if (svgName === 'github') return <GithubIcon fill={color} size={size} />
  if (svgName === 'vimeo') return <VimeoIcon fill={color} size={size} />
  if (svgName === 'imdb') return <ImdbIcon fill={color} size={size} />
  return undefined
}

export default memo(SvgIconHandler)
