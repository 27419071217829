export const darkTheme = {
  body: '#282828',
  fontMain: '#FFF',
  fontAlt: '#DDD',
}

export const lightTheme = {
  body: '#EDEDED',
  fontMain: '#000',
  fontAlt: '#333',
}
