import styled from 'styled-components'

export const ToggleContainer = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.fontAlt};
  cursor: pointer;
  font-size: 0.8rem;

  svg {
    height: auto;
    width: 1.2rem;
    transition: all 0.3s linear;
  }
`
