import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
    opacity: '.8',
  },
  tooltip: {
    backgroundColor: 'black',
    opacity: '.8',
  },
}))

export default function StyledTooltip(props) {
  const classes = useStylesBootstrap()
  return <Tooltip arrow classes={classes} {...props} />
}
