import React, { useContext } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../utils/LanguageContext'
import { MenuItem, FormControl, Select } from '@material-ui/core'

const StyledForm = styled(FormControl)`
  margin: 8px;
  min-width: 120px;
`

const StyledSelect = styled(Select)`
  width: auto;
`

export default function SimpleSelect({ theme }) {
  const { language, handleChangeLanguage } = useContext(LanguageContext)
  const isLight = theme === 'light'

  const languageValue = (language) => {
    if (language.value === 'en-US') {
      return 'EN'
    }
    if (language.value === 'es') {
      return 'ES'
    }
    if (language.value === 'pt-BR') {
      return 'PT'
    }
  }

  return (
    <>
      <StyledForm>
        <StyledSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={languageValue(language)}
          style={{ color: isLight ? 'black' : 'white' }}
          onChange={(e) => handleChangeLanguage(e.target.value)}
        >
          <MenuItem value={'EN'}>en-US</MenuItem>
          <MenuItem value={'ES'}>es</MenuItem>
          <MenuItem value={'PT'}>pt-BR</MenuItem>
        </StyledSelect>
      </StyledForm>
    </>
  )
}
