import React, { useContext } from 'react'
import { LanguageContext } from '../utils/LanguageContext'
import styled from 'styled-components'

const Container = styled.section`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MailTo = styled.a`
  color: ${({ theme }) => theme.fontAlt};
  font-size: 1.3rem;
  font-style: italic;
  text-decoration: none;
  margin-right: 1em;
  &:hover {
    filter: brightness(0.8);
  }
  &.visited {
    color: ${({ theme }) => theme.fontAlt};
  }
`

const Copy = styled.p`
  margin: 5px 0 0 1em;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.fontAlt};
  @media (max-width: 400px) {
    display: none;
  }
`

const Footer = () => {
  const { language } = useContext(LanguageContext)
  return (
    <Container>
      <Copy>
        &copy;
        {` ${new Date().getFullYear()} Abel Roland. ${language.copy}`}
      </Copy>
      <MailTo href="mailto:info@abelroland.com">{language.contact}</MailTo>
    </Container>
  )
}

export default Footer
