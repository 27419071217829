import { useState, useContext } from 'react'
import { icons } from './helper'
import {
  SvgIconHandler,
  StyledTooltip,
  Footer,
  Toogle,
  SimpleSelect,
} from './components'
import { useDarkMode } from './utils/useDarkMode'
import { LanguageContext } from './utils/LanguageContext'
import * as S from './style'
import { lightTheme, darkTheme } from './utils/colors'
import { ThemeProvider } from 'styled-components'

function App() {
  const [iconHovered, setIconHovered] = useState(undefined)
  const [theme, toggleTheme] = useDarkMode()
  const { language } = useContext(LanguageContext)
  const themeMode = theme === 'dark' ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={themeMode}>
      <S.GlobalStyles />
      <S.Container>
        <S.Navbar>
          <SimpleSelect theme={theme} />
          <Toogle theme={theme} toggleTheme={toggleTheme} />
        </S.Navbar>
        <S.Body>
          <S.Name>Abel Roland</S.Name>
          <S.Pitch>{language.pitch}</S.Pitch>
          <S.Pitch>{language.pitch}</S.Pitch>
          <S.TextContainer>
            <p>{language.phraseOne}</p>
            <p>{language.phraseTwo}</p>
          </S.TextContainer>
          <S.LinksContainer>
            {icons.map((icon, index) => (
              <div key={index}>
                <StyledTooltip title={`${language.label} ${icon.label}`}>
                  <S.Link
                    href={icon.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseOver={() => setIconHovered(icon.name)}
                    onMouseLeave={() => setIconHovered(undefined)}
                  >
                    <SvgIconHandler
                      svgName={icon.name}
                      color={
                        icon.name === iconHovered
                          ? icon.color
                          : theme === 'light'
                          ? '#121212'
                          : '#EDEDED'
                      }
                    />
                  </S.Link>
                </StyledTooltip>
              </div>
            ))}
          </S.LinksContainer>
        </S.Body>
        <Footer theme={theme} />
      </S.Container>
    </ThemeProvider>
  )
}

export default App
