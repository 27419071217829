import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`

export const Container = styled.main`
  background-color: ${({ theme }) => theme.body};
  text-align: center;
`

export const Navbar = styled.nav`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  height: 40px;
`

export const Body = styled.section`
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: ${({ theme }) => theme.fontMain};
`

export const TextContainer = styled.div`
  width: 80%;
  @media (max-width: 400px) {
    width: 95%;
  }
`

export const SelectContainer = styled.div`
  position: absolute;
  right: 3.5rem;
  top: 0.2rem;
`

export const Name = styled.h1`
  font-size: 3.5rem;
  font-weight: 100;
  color: ${({ theme }) => theme.fontAlt};

  @media (max-width: 400px) {
    font-size: 2rem;
  }
`

export const Pitch = styled.p`
  font-size: 1.1rem;
  font-style: italic;
  padding: 0 50px;
  @media (max-width: 400px) {
    font-size: 0.8rem;
    padding: 0 20px;
  }
`
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Link = styled.a`
  padding: 0 10px;
`
