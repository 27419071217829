import React, { createContext, useContext, useState } from 'react'
import { EN, ES, PT } from './Lang'

export const LanguageContext = createContext({})

export default function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(EN)

  const handleChangeLanguage = (lang) => {
    if (lang === 'EN') {
      window.localStorage.setItem('lang', 'EN')
      setLanguage(EN)
    } else if (lang === 'ES') {
      window.localStorage.setItem('lang', 'ES')
      setLanguage(ES)
    } else {
      window.localStorage.setItem('lang', 'PT')
      setLanguage(PT)
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        handleChangeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => useContext(createContext)
